var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      class: "collapse nav-dropdown-" + _vm.navDropdownCounter,
      attrs: { "data-level": "1" }
    },
    [
      _vm.isNavigationInitialized
        ? _vm._l(_vm.categories, function(category) {
            return _c("li", { staticClass: "collapse-inner level1" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.getCategoryUrl(category.url),
                    title: category.details[0].metaTitle
                  }
                },
                [_vm._v(_vm._s(category.details[0].name))]
              ),
              _vm._v(" "),
              _vm.showNextLevel(category)
                ? _c(
                    "ul",
                    _vm._l(category.children, function(child) {
                      return _c("desktop-navigation-node", {
                        key: child.id,
                        attrs: { category: child, level: 2 }
                      })
                    }),
                    1
                  )
                : _vm._e()
            ])
          })
        : [_c("li", [_c("loading-animation")], 1)]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }