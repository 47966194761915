var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notification-wrapper" },
    _vm._l(_vm.filteredNotifications, function(notification) {
      return _c(
        "div",
        {
          key: notification.id,
          class:
            "alert alert-dismissible fade in show alert-" +
            notification.context,
          attrs: { role: "alert" }
        },
        [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: {
                type: "button",
                "aria-label": _vm.$translate("Ceres::Template.closeIcon")
              },
              on: {
                click: function($event) {
                  return notification.close()
                }
              }
            },
            [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
          ),
          _vm._v(" "),
          _vm.showErrorCode
            ? _c("strong", [_vm._v(_vm._s(notification.code))])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { domProps: { innerHTML: _vm._s(notification.message) } }),
          _vm._v(" "),
          _vm._l(notification.stackTrace, function(trace, index) {
            return _c("p", { key: index, staticClass: "small" }, [
              _vm._v("\n            " + _vm._s(trace.message) + "\n        ")
            ])
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }