var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sizes
    ? _c("img", {
        class: _vm.pictureClass,
        attrs: {
          "data-src": _vm.fallbackUrl,
          "data-srcset": _vm.imageUrl,
          sizes: _vm.sizes,
          alt: _vm.alt,
          title: _vm.title
        }
      })
    : !_vm.isBackgroundImage
    ? _c(
        "picture",
        {
          attrs: {
            "data-iesrc": _vm.fallbackUrl || _vm.imageUrl,
            "data-picture-class": _vm.pictureClass,
            "data-alt": _vm.alt,
            "data-title": _vm.title
          }
        },
        [
          _vm._t("additionalimages"),
          _vm._v(" "),
          _c("source", { attrs: { srcset: _vm.imageUrl, type: _vm.mimeType } }),
          _vm._v(" "),
          _vm.fallbackUrl
            ? _c("source", { attrs: { srcset: _vm.fallbackUrl } })
            : _vm._e()
        ],
        2
      )
    : _c(
        "div",
        {
          class: _vm.pictureClass,
          attrs: { "data-background-image": _vm.backgroundSource }
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }