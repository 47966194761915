var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      (!_vm.isSetLoading && _vm.variation) || _vm.$ceres.isShopBuilder
        ? _vm._t("default", null, {
            itemId: _vm.itemId,
            variationId: _vm.variationId,
            variation: _vm.variation,
            getDataField: _vm.getDataField,
            getFilteredDataField: _vm.getFilteredDataField
          })
        : _vm.isSetLoading
        ? _c("loading-animation", { staticClass: "prop-3-1" })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }