import { render, staticRenderFns } from "./LanguageDetection.vue?vue&type=template&id=42e7aa86&"
import script from "./LanguageDetection.vue?vue&type=script&lang=js&"
export * from "./LanguageDetection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/uweschurmann/PhpstormProjects/19338/15137/51/HydronalinTheme/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42e7aa86')) {
      api.createRecord('42e7aa86', component.options)
    } else {
      api.reload('42e7aa86', component.options)
    }
    module.hot.accept("./LanguageDetection.vue?vue&type=template&id=42e7aa86&", function () {
      api.rerender('42e7aa86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/src/app/components/pageDesign/LanguageDetection.vue"
export default component.exports