var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "position-relative user-controls" }, [
    _vm.isLoggedIn
      ? _c("div", { staticClass: "dropdown" }, [
          _c(
            "a",
            {
              staticClass: "dropdown-toggle nav-link",
              attrs: {
                href: "#",
                id: "accountMenuList",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
                "data-boundary": "window"
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-user d-sm-none d-md-inline d-lg-none",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "d-none d-sm-inline d-md-none d-lg-inline ml-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$translate("Ceres::Template.loginHello", {
                        username: _vm.username
                      })
                    )
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dropdown-menu small m-0 p-0 mw-100" }, [
            _c(
              "div",
              {
                staticClass: "list-group",
                attrs: { "aria-labelledby": "accountMenuList" }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "list-group-item",
                    attrs: { href: _vm.$ceres.urls.myAccount }
                  },
                  [
                    _c("i", { staticClass: "fa fa-user" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$translate("Ceres::Template.loginMyAccount"))
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [{ name: "logout", rawName: "v-logout" }],
                    staticClass: "list-group-item",
                    attrs: { href: "#" }
                  },
                  [
                    _c("i", { staticClass: "fa fa-sign-out" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$translate("Ceres::Template.loginLogout"))
                    )
                  ]
                )
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoggedIn
      ? _c(
          "div",
          [
            _vm.showLogin
              ? _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-testing": "login-select",
                      href: _vm.isLogin ? "javascript:void(0)" : "#login",
                      "data-toggle": _vm.isLogin ? false : "modal",
                      "aria-label": _vm.$translate("Ceres::Template.login")
                    },
                    on: {
                      click: function($event) {
                        _vm.createLoginModal()
                        _vm.unmarkInputFields()
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-user",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-none d-sm-inline d-md-none d-lg-inline ml-1"
                      },
                      [_vm._v(_vm._s(_vm.$translate("Ceres::Template.login")))]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showRegistration
              ? [
                  !_vm.showLogin
                    ? _c("span", { staticClass: "pipe" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        "data-testing": "register-select",
                        href: _vm.isRegister
                          ? "javascript:void(0)"
                          : "#registration",
                        "data-toggle": _vm.isRegister ? false : "modal",
                        "aria-label": _vm.$translate(
                          "Ceres::Template.loginRegister"
                        )
                      },
                      on: {
                        click: function($event) {
                          _vm.createRegisterModal()
                          _vm.unmarkInputFields()
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-user-plus",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "d-none d-sm-inline d-md-none d-lg-inline ml-1"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$translate("Ceres::Template.loginRegister")
                            )
                          )
                        ]
                      )
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }