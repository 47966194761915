var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.targetLang
    ? _c("div", { staticClass: "bg-appearance" }, [
        _c("div", { staticClass: "container-max" }, [
          _c("div", { staticClass: "d-flex py-2" }, [
            _c("div", { staticClass: "align-self-center mr-auto" }, [
              _vm._v(_vm._s(_vm.textTranslations[_vm.targetLang]))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "align-self-center text-nowrap" }, [
              _c(
                "a",
                {
                  class: "btn btn-sm btn-appearance",
                  attrs: { href: _vm.redirectUrl }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.buttonTranslations[_vm.targetLang]) +
                      "\n                "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "m-sm-1",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.refuseRedirect()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-fw fa-close" })]
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }