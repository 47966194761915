var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { class: "level" + _vm.level }, [
    _c(
      "a",
      {
        attrs: {
          href: _vm.getCategoryUrl(_vm.category.url),
          title: _vm.category.details[0].metaTitle
        }
      },
      [_vm._v(_vm._s(_vm.category.details[0].name))]
    ),
    _vm._v(" "),
    _vm.showNextLevel(_vm.category)
      ? _c(
          "ul",
          _vm._l(_vm.category.children, function(child) {
            return _c("desktop-navigation-node", {
              key: child.id,
              attrs: { category: child, level: _vm.level + 1 }
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }