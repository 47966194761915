var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "nav-link position-relative",
      attrs: {
        href: _vm.urlWishList,
        "aria-label": _vm.$translate("Ceres::Template.wishList")
      }
    },
    [
      _c(
        "span",
        {
          staticClass:
            "position-absolute text-center w-100 text-white wishlist-count"
        },
        [_vm._v(_vm._s(_vm.wishListCount))]
      ),
      _vm._v(" "),
      _c("i", {
        staticClass: "fa fa-heart",
        class: { "text-secondary": _vm.wishListCount },
        attrs: { "aria-hidden": "true" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }