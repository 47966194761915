import MonetaryFormatter from "../helper/MonetaryFormatter";
import Vue from "vue";

const formatter = new MonetaryFormatter();

Vue.filter("currency", function(price, currency = App.activeCurrency, sup = false)
{
    if (price === "N / A")
    {
        return price;
    }

    return formatter.format(parseFloat(price), currency, sup);
});
