<template>
    <a class="nav-link position-relative" :href="urlWishList" :aria-label="$translate('Ceres::Template.wishList')">
        <span class="position-absolute text-center w-100 text-white wishlist-count">{{ wishListCount }}</span>
        <i class="fa fa-heart" :class="{'text-secondary': wishListCount}" aria-hidden="true"></i>
    </a>
</template>

<script>
export default {
    computed:
    {
        wishListCount()
        {
            return this.$store.getters.wishListCount;
        },

        urlWishList()
        {
            return App.urls.wishList;
        }
    }
}
</script>
